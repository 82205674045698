.box{
     border-radius: 10px;
     background-color: white;
}

.btnx{
     width: 100%;
     margin: 10px 0px;
     border: none;
     border-radius: 20px;
     background-color: rgb(78, 78, 240);
     color: white;
     padding: 10px 0;
}

.dropdown_x{
     width: 90% !important;
     height: 55px !important;
     margin: 0 !important;
     margin-top: 20px !important;
}

.add_action_btn{
     border: none;
     border-radius: 5px;
     padding: 0 10px;
     background-color: rgb(27, 165, 27);
     color: white;
}

.remove_action_btn{
     border: none;
     border-radius: 5px;
     padding: 0 10px;
     background-color: rgb(219, 23, 65);
     color: white;
}