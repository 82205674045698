@import url(https://fonts.googleapis.com/css?family=Karla);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: #f1f1f1 !important;
}

*{
    margin: 0;
    padding: 0;
    outline: none !important;
}

/* *::-webkit-scrollbar{
  width: 0;
} */

.b{
      border: 2px solid red;
}

.__dropdown_menu{
    border: 1px solid rgb(129, 129, 129);
    border-radius: 5px;
    padding: 0px 5px 0px 15px !important;
    margin: 20px 0 !important;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
}
 /* table style */
.__table_root{
    background-color: white;
    height: auto;
    border-radius: 10px;
    padding: 20px !important;
    margin-bottom: 54px;
}
.table {  
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: unset !important;  
  width: 100%;
  border: none !important;
}
td, th{
  border-bottom: .1px solid #ebedf2 !important;
}
tr:last-child>td {
  border-bottom: none !important;
}
.table td, .table th {
  padding: 0.9375rem;
  border: none;
}
.table th {
  padding: 0.9375rem;
  text-align: left;
  color: rgb(75, 75, 75);
}

.img_profile_photo{
  border-radius: 50%;
  width: 40px;
}
.img_banner{
  width: 60px;
}

.nav_link{
  color: #212529 !important;
  text-decoration: none;
}

.nav_link:hover{
  text-decoration: underline;
}

.item_status_view {
  /* padding: 5px 10px;
  border-radius: 4px; */
  font-weight: bold;
}

.status_c_yellow{
  color: rgb(247, 181, 0);
}
.status_c_blue{
  color: #4c00c7;
}
.status_c_red{
  color: #df1111;
}
.status_c_green{
  color: #07a11c;
}

.item_action_btn{
  border: none;
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  padding: 5px 15px !important;
  font-size: 12px;
}

.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar button {
  outline: none !important;
}

.content-wrapper{
  padding-top: 10px;
}
.__btn{
     border: 0;
     color: white;
     text-transform: capitalize;
     border-radius: 5px;
     padding: 10px 0;
     background: #5213ff;
}

.App {
     text-align: center;
   }
   
   .App-logo {
     height: 40vmin;
     pointer-events: none;
   }
   
   @media (prefers-reduced-motion: no-preference) {
     .App-logo {
       animation: App-logo-spin infinite 20s linear;
     }
   }
   
   .App-header {
     background-color: #282c34;
     min-height: 100vh;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     font-size: calc(10px + 2vmin);
     color: rgb(13, 104, 240);
   }
   
   .App-link {
     color: #61dafb;
   }
   
   .p-select{
     padding: 10px 30px !important;
   }
   
   @keyframes App-logo-spin {
     from {
       transform: rotate(0deg);
     }
     to {
       transform: rotate(360deg);
     }
   }
.login_container {
    height: 400px;
    width: 400px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 5px 15px 30px #e7e7e7;
}
/* .__header{
     height: 60px;
     background-color: #fff;
} */
.Deshboard_Item {
     height: 140px;
     margin: 0;
     padding: 0 5px;
}

.Deshboard_Item_inner{
     height: 100%;
     width: 100%;
     box-shadow: 5px 5px 20px #e4e1e1;
     border-radius: 5px;
     padding: 25px 20px 0px 15px;
     /* background: linear-gradient(153deg, rgba(245,237,161,1) 0%, rgba(195,163,244,1) 100%); */
     /* background: linear-gradient(153deg, rgba(79,67,210,1) 0%, rgba(195,163,244,1) 100%); */
     background: linear-gradient(153deg, rgba(79,67,210,1) 0%, rgba(9,50,152,1) 100%);
}
.box{
     border-radius: 10px;
     background-color: white;
}

.btnx{
     width: 100%;
     margin: 10px 0px;
     border: none;
     border-radius: 20px;
     background-color: rgb(78, 78, 240);
     color: white;
     padding: 10px 0;
}

.dropdown_x{
     width: 90% !important;
     height: 55px !important;
     margin: 0 !important;
     margin-top: 20px !important;
}

.add_action_btn{
     border: none;
     border-radius: 5px;
     padding: 0 10px;
     background-color: rgb(27, 165, 27);
     color: white;
}

.remove_action_btn{
     border: none;
     border-radius: 5px;
     padding: 0 10px;
     background-color: rgb(219, 23, 65);
     color: white;
}
.react-switch-root{
     background-color: #d3d3d3;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 45px;
  height: 20px;
  background: #d3d3d3;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  left:0px;
  width: 20px;
  height: 20px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 5px #c0c0c0;
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100%);
  transform: translateX(-100%);
}
.react-switch-label .react-switch-button:hover{
     box-shadow: 0 0 10px #474747;
}
.react-switch-label-checked {
     background: rgba(79,67,210,1);
}

.react-switch-label:active .react-switch-button {
  width: 20px;
}
.notification-import-image-root{
     height: 100px;
     width: 200px;
     background-color: rgb(238, 238, 238);
     border-radius: 5px;
}

.notification_container {
     /* height: 670px; */
     width: 400px;
     border-radius: 10px;
     background-color: white;
     box-shadow: 5px 15px 30px #e7e7e7;
 }
.banner_create{
     background-color: rgba(79,67,210,1);
     border-radius: 5px;
     padding: 5px 15px 5px 10px;
     margin-right: 10px;
     cursor: pointer;
}
.banner_create>button{
     border: none;
     color: white;
     margin-left: 5px;
     background-color: rgba(79,67,210,1);
}
.banner-rediract-activity-root{
     height: 55px !important;
     margin: 0 !important;
}
* {
  box-sizing: border-box;
}

::selection {
  background: rgb(114, 175, 231);
  color: #222;
}


br {
  font-size: 0;
}

/* body {
  font-family: "Karla", sans-serif;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  background-color: #ddd;
} */

.subject-box {
  height: 85vh;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  margin-top: 20px;
}

.chat-box {
  height: 85vh;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  margin-top: 20px;
}
.m-header {
  color: #000000;
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid  #f1f1f1;;
}
.m-header > * {
  line-height: 40px;
}
.m-header h1 {
  margin: 0;
  font-size: 1.3em;
}
.m-header .close-button {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.chat-box section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 100px - 110px);
  padding: 12px;
  margin-bottom: 10px;
}
.chat-box section::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.chat-box section div {
  margin: 12px 0;
}
.chat-box section span {
  display: inline-block;
  padding: 6px 8px;
  margin: 1px 0;
  max-width: 80%;
  word-wrap: break-word;
}
.chat-box section div.me span a {
  color: #000000;
}
.chat-box section div.me span a:hover {
  color: #0059ff;
}
.chat-box section div.me {
  text-align: left;
}
.chat-box section div.me span {
  background-color: rgb(170, 199, 255);
  border-radius: 4px 16px 16px 4px;
  color: rgb(0, 0, 0);
  font-size: 1em;
}
.chat-box section div.me span:first-of-type {
  border-top-left-radius: 16px;
}
.chat-box section div.me span:last-of-type {
  border-bottom-left-radius: 16px;
}
.chat-box section div.not-me {
  text-align: right;
}
.chat-box section div.not-me span {
  background-color: #f1f1f1;
  border-radius: 16px 4px 4px 16px;
  font-size: 1em;
}
.chat-box section div.not-me span:first-of-type {
  border-top-right-radius: 16px;
}
.chat-box section div.not-me span:last-of-type {
  border-bottom-right-radius: 16px;
}
.chat-box footer{
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.chat-box footer textarea {
  font-family: "Karla", sans-serif;
  outline: 0;
  padding: 0 12px;
  height: 130px;
  width: 90%;
  font-size: 1em;
  border: none;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 10px 10px;
  flex: 10 1;
}

.chat-actions{
  width: 90%;
  font-size: 1em;
  border: none;
  background-color: #674cfd;
  border-radius: 10px;
  padding: 10px 10px;
  flex: 1 1;
  margin-left: 10px;
  color: white;
  white-space: nowrap;
}

.chat-box .line{
  height: 10px;
  width: 100%;
  background-color: #000000 !important;
}
