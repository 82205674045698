.notification-import-image-root{
     height: 100px;
     width: 200px;
     background-color: rgb(238, 238, 238);
     border-radius: 5px;
}

.notification_container {
     /* height: 670px; */
     width: 400px;
     border-radius: 10px;
     background-color: white;
     box-shadow: 5px 15px 30px #e7e7e7;
 }