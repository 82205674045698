@import url("https://fonts.googleapis.com/css?family=Karla");
* {
  box-sizing: border-box;
}

::selection {
  background: rgb(114, 175, 231);
  color: #222;
}


br {
  font-size: 0;
}

/* body {
  font-family: "Karla", sans-serif;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  background-color: #ddd;
} */

.subject-box {
  height: 85vh;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  margin-top: 20px;
}

.chat-box {
  height: 85vh;
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  margin-top: 20px;
}
.m-header {
  color: #000000;
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid  #f1f1f1;;
}
.m-header > * {
  line-height: 40px;
}
.m-header h1 {
  margin: 0;
  font-size: 1.3em;
}
.m-header .close-button {
  cursor: pointer;
  user-select: none;
}
.chat-box section {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 100px - 110px);
  padding: 12px;
  margin-bottom: 10px;
}
.chat-box section::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
.chat-box section div {
  margin: 12px 0;
}
.chat-box section span {
  display: inline-block;
  padding: 6px 8px;
  margin: 1px 0;
  max-width: 80%;
  word-wrap: break-word;
}
.chat-box section div.me span a {
  color: #000000;
}
.chat-box section div.me span a:hover {
  color: #0059ff;
}
.chat-box section div.me {
  text-align: left;
}
.chat-box section div.me span {
  background-color: rgb(170, 199, 255);
  border-radius: 4px 16px 16px 4px;
  color: rgb(0, 0, 0);
  font-size: 1em;
}
.chat-box section div.me span:first-of-type {
  border-top-left-radius: 16px;
}
.chat-box section div.me span:last-of-type {
  border-bottom-left-radius: 16px;
}
.chat-box section div.not-me {
  text-align: right;
}
.chat-box section div.not-me span {
  background-color: #f1f1f1;
  border-radius: 16px 4px 4px 16px;
  font-size: 1em;
}
.chat-box section div.not-me span:first-of-type {
  border-top-right-radius: 16px;
}
.chat-box section div.not-me span:last-of-type {
  border-bottom-right-radius: 16px;
}
.chat-box footer{
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;
}
.chat-box footer textarea {
  font-family: "Karla", sans-serif;
  outline: 0;
  padding: 0 12px;
  height: 130px;
  width: 90%;
  font-size: 1em;
  border: none;
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 10px 10px;
  flex: 10;
}

.chat-actions{
  width: 90%;
  font-size: 1em;
  border: none;
  background-color: #674cfd;
  border-radius: 10px;
  padding: 10px 10px;
  flex: 1;
  margin-left: 10px;
  color: white;
  white-space: nowrap;
}

.chat-box .line{
  height: 10px;
  width: 100%;
  background-color: #000000 !important;
}