body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: #f1f1f1 !important;
}

*{
    margin: 0;
    padding: 0;
    outline: none !important;
}

/* *::-webkit-scrollbar{
  width: 0;
} */

.b{
      border: 2px solid red;
}

.__dropdown_menu{
    border: 1px solid rgb(129, 129, 129);
    border-radius: 5px;
    padding: 0px 5px 0px 15px !important;
    margin: 20px 0 !important;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
}
 /* table style */
.__table_root{
    background-color: white;
    height: auto;
    border-radius: 10px;
    padding: 20px !important;
    margin-bottom: 54px;
}
.table {  
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: unset !important;  
  width: 100%;
  border: none !important;
}
td, th{
  border-bottom: .1px solid #ebedf2 !important;
}
tr:last-child>td {
  border-bottom: none !important;
}
.table td, .table th {
  padding: 0.9375rem;
  border: none;
}
.table th {
  padding: 0.9375rem;
  text-align: left;
  color: rgb(75, 75, 75);
}

.img_profile_photo{
  border-radius: 50%;
  width: 40px;
}
.img_banner{
  width: 60px;
}

.nav_link{
  color: #212529 !important;
  text-decoration: none;
}

.nav_link:hover{
  text-decoration: underline;
}

.item_status_view {
  /* padding: 5px 10px;
  border-radius: 4px; */
  font-weight: bold;
}

.status_c_yellow{
  color: rgb(247, 181, 0);
}
.status_c_blue{
  color: #4c00c7;
}
.status_c_red{
  color: #df1111;
}
.status_c_green{
  color: #07a11c;
}

.item_action_btn{
  border: none;
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  padding: 5px 15px !important;
  font-size: 12px;
}

.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.navbar button {
  outline: none !important;
}

.content-wrapper{
  padding-top: 10px;
}